import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './guides.scss';
import '../../generic-page.scss';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Table } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';
import ScrollspyNav from 'react-scrollspy-nav';

const RevGuidesIntroPage: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-guide'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Introduction to the game</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_intro.png"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Introduction to the game</h1>
          <h2>Everything you need to know about Reverse: 1999 gacha game.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Foreword" />
              <p>
                Special thanks to <strong>Liriell</strong> who worked tirelessly
                on this guide.
              </p>
              <SectionHeader title="Introduction" />
              <p>
                Reverse: 1999 is a{' '}
                <strong>
                  turn-based RPG with a combat system revolving around the use
                  of spell cards
                </strong>{' '}
                (incantations). The game focuses heavily on storytelling, having
                a Main Story mode and recurring side-story events.{' '}
                <strong>There's no PvP nor any competitive mode</strong>.
              </p>
              <p>
                It is distinguished not only by its{' '}
                <strong>strong cast of Voice Actors,</strong> but the diversity
                of characters, each one with unique design, story and accent.
                Another selling point is the fantastic art style presented both
                through illustrations, character art, animated PVs and L2D for
                all characters.
              </p>
              <p>
                <strong>
                  For those looking for an entertaining gacha who is F2P
                  friendly and won't overtake your time or your wallet, this
                  game may be for you
                </strong>
                . Reverse:1999 constantly adds new stories and events, each one
                with their own gameplay twist and mini-games to the side.
                Quality of life features such as being able to re-clear a stage
                with up to four times the rewards in a sped-up autoplay make
                finishing dailies and farming a smoother experience.
              </p>
              <p>
                For more information on all these features as well as the gacha
                itself, check the respective sections in this guide. Now, are
                you ready? Just step through the door…
              </p>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Setting" />
              <StaticImage
                src="../../../images/reverse/generic/banner_intro.png"
                alt="Guides"
              />
              <p>
                Imagine a world much like our own, but where on one side you
                have regular humans, while on the other, there are Arcanists:
                people capable of wielding magic, supernatural beings ranging
                from mythical figures to objects who developed a conscience of
                their own, bodyless minds, fantastical creatures and urban
                legends. It’s only natural to expect that factions would form,
                and that clashes would be constant between them.
              </p>
              <p>Regardless of all that, life moved on.</p>
              <p>
                <strong>Until the eve of the millennium.</strong>
              </p>
              <p>December 31, 1999. Time? 23:59.</p>
              <p>
                It started with a single drop of rain. It abandoned the confines
                of gravity and headed skyward, soon followed by all other
                raindrops. Gradually the rain washed out reality, undoing, in
                its reverse path, everything about that time. Places, feats,
                memories, people, all forever gone, forever forgotten, forever
                lost, as if it had never existed.
              </p>
              <p>
                Amidst the mix of chaos, culminating in absolute emptiness,
                comes our main character, Vertin. Carrying the title of
                Timekeeper, Vertin's agenda is filled with goals of her own, as
                well as those set by the St. Pavlov's Foundation. The player
                follows Vertin's steps as she travels through different time
                periods, attempting to salvage as much as possible before each
                era gets sifted out by the Storm. In this uncertain path, she
                meets Arcanists, creates alliances, and aims to learn the truth
                behind the Storm itself and the year 1999.
              </p>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Gameplay" />
              <StaticImage
                src="../../../images/reverse/generic/banner_game.png"
                alt="Guides"
              />
              <h5>Combat</h5>
              <p>
                As mentioned in the introduction, Reverse: 1999 is a turn-based
                strategy RPG with combat based around a card system. Each
                character has passive skills, two incantation cards and one
                ultimate card.
              </p>
              <p>
                They are also classified into one of six{' '}
                <strong>Afflatus</strong> (Beast, Plant, Star, Mineral, Spirit,
                Intellect) and one of two <strong>damage types</strong> (Reality
                and Mental). For the incantation cards, their level ranges from
                1 to 3. You can increase a card's level by combining two of the
                same type and level, or using Tuning skills. Sometimes,
                upgrading a card gives it extra effects and can even change its
                classification.
              </p>
              <StaticImage
                src="../../../images/reverse/generic/afflatus.jpg"
                alt="Guides"
              />
              <p>
                There are <strong>six types of cards</strong>, and during
                combat, you can see which type and which level the enemy is
                about to use:
              </p>
              <StaticImage
                src="../../../images/reverse/generic/incan.jpg"
                alt="Guides"
              />
              <p>
                Aside from this, there are also two set of{' '}
                <strong>“Tuning Skills”</strong>. They are Vertin’s skills, and
                allow for a twist in strategy through timely card upgrades,
                randomization or being able to move a card without AP cost
                during a round.{' '}
              </p>
              <p>
                When entering combat, the player can bring up to four
                characters. Usually,{' '}
                <strong>only three of them will be in the field</strong>, with
                the fourth one being labeled as a “substitute” and joining when
                someone dies. There are cases, however, when the four of them
                are in the field (usually in boss battles), or fewer characters
                are available per time while the rest of the team waits.
              </p>
              <StaticImage
                src="../../../images/reverse/generic/combat_info.jpg"
                alt="Guides"
              />
              <h5>Character Progression</h5>
              <StaticImage
                src="../../../images/reverse/generic/char_prog.jpg"
                alt="Guides"
              />
              <p>
                Characters can be upgraded in a number of ways. The first and
                most core one, being their level. Once a character reaches max
                level, they can increase their Insight level. Upgrading insight
                requires some materials, and after doing so, it will unlock a
                new passive, increase the maximum level of the character and
                reset their current level to one. At insight 2, a new appearance
                and outfit are unlocked for characters of rarity 4-star and
                above.
              </p>
              <h5>Psychubes</h5>
              <p>
                <strong>
                  Psychubes are the equivalent of gear, or equipment, in this
                  game
                </strong>
                . They offer a bonus of attributes, and the ones of five and
                six-star rarity have passives of their own. Psychubes can be
                obtaining by farming their stages, exchanging the currency from
                their stages for new ones in the Psychube shop, or exchanging
                the side-story event currency in the event shop. They can also
                be upgraded to give even better attributes and passives.
              </p>
              <h5>Portray</h5>
              <p>
                <strong>
                  “Portray” refers to the dupe system in Reverse:1999.
                </strong>{' '}
                Dupes aren’t super important in this game, as their only
                function is to increase the base number of the character’s
                skills. Unlike some other gachas, it doesn’t unlock new
                functions for the characters and doesn’t change the way they are
                played. It is, of course, nice to have slightly stronger
                multipliers, and relatively important for the low rarity units,
                so they can keep up with the high rarity ones for longer or
                offer more utility. Thankfully, it’s easy to get portrays for
                these.
              </p>
              <h5>Resonance</h5>
              <p>
                <strong>
                  Resonance is another important aspect of character
                  progression.
                </strong>{' '}
                It increases the base stats of the character, and is
                customizable to an extent, taking the form of a “puzzle” where
                the player can either manually load the pieces with the
                attributes they wish, or let the game automatically quick load
                all the grids. This allows for some min-maxing, as well as some
                variety in builds of some characters. Fair warning, the amount
                of materials obtainable for upgrading Resonance is limited each
                month, so it’s advised to plan carefully who to invest Resonance
                levels in.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Game Modes" />
              <StaticImage
                src="../../../images/reverse/generic/banner_modes.png"
                alt="Guides"
              />
              <h5>Main Story</h5>
              <p>
                The Main Story follows Vertin, and focuses on her investigations
                about the Storm, the St. Pavlov Foundation and the Manus
                Vindictae. It should be a priority for any player, as the Main
                Story is required to unlock most of the other features and game
                modes.
              </p>
              <p>
                By finishing the first chapter, you unlock the{' '}
                <strong>Wilderness</strong> and also the Side-Story events. By
                progressing through the third chapter, you unlock the
                "Artificial Somnambulism" game mode.
              </p>
              <p>
                As you progress, you can explore the map and search for "Traces"
                little clues that add to the worldbuilding through newspaper
                articles, overheard conversations, pictures and puzzles. The map
                is designed like a stylized pop-up story book, with the
                characters taking the form of little tabletop game pieces who
                follow along as the story unfolds.
              </p>
              <h5>Side-Story Events</h5>
              <p>
                Each event has its own themes, rewards, story, mini-games and
                other modes. They expand further on the worldbuilding and the
                background of the featured characters. Each event also has its
                own currency and shop, which makes them an efficient source of
                materials, Dust (character exp) and Sharpodonty (coins/gold).
              </p>
              <p>
                Normally the event structure consists of a first half focused on
                story mode, and a second half featuring the UTTU challenge, one
                of the "endgame" modes of Reverse 1999.
              </p>
              <h6>Event roadmap</h6>
              <Row xs={1} xl={2} xxl={3} className="roadmap">
                <Col>
                  <div className="box">
                    <StaticImage
                      src="../../../images/reverse/generic/roadmap_1.jpg"
                      alt="Guides"
                    />
                    <p>1.1 - The Theft of the Rimet Cup</p>
                  </div>
                </Col>
                <Col>
                  <div className="box">
                    <StaticImage
                      src="../../../images/reverse/generic/roadmap_2.jpg"
                      alt="Guides"
                    />
                    <p>1.2 - A Nightmare at Green Lake</p>
                  </div>
                </Col>
                <Col>
                  <div className="box">
                    <StaticImage
                      src="../../../images/reverse/generic/roadmap_3.jpg"
                      alt="Guides"
                    />
                    <p>1.3 - Journey to Mor Pankh</p>
                  </div>
                </Col>
              </Row>
              <h5>Wilderness</h5>
              <p>
                A casual game mode where the player can{' '}
                <strong>design their own island</strong>. New terrain pieces and
                buildings can be obtained by completing objectives, acquiring
                them from the wilderness shop, the event shop, and as rewards
                from specific events (such as a character's birthday).
              </p>
              <p>
                In the Wilderness it's also possible to craft materials and
                passively farm character bond, Dust and Sharpodonty. Resource
                output and character capacity can be increased both by upgrading
                the main buildings, and by placing Buildings and Islands in the
                Wilderness.
              </p>
              <h6>Main buildings</h6>
              <Row xs={1} xl={2} xxl={2} className="buildings">
                <Col>
                  <div className="box">
                    <StaticImage
                      src="../../../images/reverse/generic/building_1.png"
                      alt="Guides"
                    />
                    <h5>Paleohall</h5>
                    <p>
                      The main building. It doesn’t do anything by itself, but
                      upgrading it is necessary to unlock upgrades for the
                      others. The main Resource needed to upgrade it can be
                      obtained in stages: 2-14, 3-15, 4-21, and by gathering 330
                      traces.
                    </p>
                  </div>
                </Col>
                <Col>
                  <div className="box">
                    <StaticImage
                      src="../../../images/reverse/generic/building_2.png"
                      alt="Guides"
                    />
                    <h5>Dust Bell Tower</h5>
                    <p>
                      The Dust (exp resource) production building. At max level,
                      can generate up to 16,200 Dust within 16 hours (Vigor
                      Bonuses not included).
                    </p>
                  </div>
                </Col>
                <Col>
                  <div className="box">
                    <StaticImage
                      src="../../../images/reverse/generic/building_3.png"
                      alt="Guides"
                    />
                    <h5>Sharpodonty Market</h5>
                    <p>
                      The Sharpodonty (coin resource) production building. At
                      max level, can generate up to 7,200 Sharpodonty within 16
                      hours (Vigor Bonuses not included).
                    </p>
                  </div>
                </Col>
                <Col>
                  <div className="box">
                    <StaticImage
                      src="../../../images/reverse/generic/building_4.png"
                      alt="Guides"
                    />
                    <h5>Wishing Spring</h5>
                    <p>
                      A building where it’s possible to craft Insight Materials,
                      Wilderness Materials, Resonate Materials, and Psychube
                      Materials.
                    </p>
                  </div>
                </Col>
              </Row>
              <h5>Artificial Somnambulism</h5>
              <p>
                This game mode is separated into "Surface" and "Limbo".{' '}
                <strong>
                  It can be considered the permanent "endgame" mode, more
                  precisely, the Limbo level.
                </strong>{' '}
                Surface is permanent in its configuration but can be replayed
                without Activity cost at any time. Surface level contains ten
                “dreams”, with each “dream” inside having five stages and a
                special rule and story of its own. At the end of every week, the
                player receives a reward proportional to the amount of stars
                collected at Surface.
              </p>
              <p>
                Meanwhile, <strong>Limbo</strong> has special rules that are
                applied to all of its stages, and significantly increased
                difficulty. Here, the rules change every two weeks, and clearing
                the stages again is necessary to reap more rewards. Limbo has,
                however, only a total of twelve stages, divided into six dreams
                with two stages each. Aside from Oneiric shop currency, clearing
                Limbo also gives Clear Drops, the gacha currency, with a total
                of up to 600 Clear Drops per round. For F2P players, it's
                advised to try to unlock this mode as soon as possible, since
                even if you can't clear everything right away, you should still
                be able to get some extra free Clear Drops.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="Income and Monetization" />
              <StaticImage
                src="../../../images/reverse/generic/banner_gacha.png"
                alt="Guides"
              />
              <p>
                <strong>
                  When it comes to gachas, Reverse: 1999 can be considered to be
                  on the generous side
                </strong>
                . Not only you can clear all content with what the game gives
                you for free out of the gate, but the income and gacha rates are
                decent. For a new player,{' '}
                <strong>
                  going through chapters 1 through 4, earns them a bit over 156
                  pulls
                </strong>
                . Aside from that, there are recurring log-in events and more
                pulls to be obtained through side-story events and by exchanging
                Lost Tracks at the shop (By completing dailies and weeklies
                alone, the player can get all ten pulls from this). Most of the
                earnings, though, come from dailies and weeklies. Below are
                listed the earnings from Free to Play, Monthly Card (“Roaring
                Month”), and Battle Pass (“Roar Jukebox”).
              </p>
              <p>
                Note: The table below doesn’t include income from events, login
                campaigns, raising bond with characters or other resources, only
                what is listed directly.
              </p>
              <Table bordered responsive className="income">
                <thead>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Free to Play (F2P)</th>
                    <th>Monthly Card</th>
                    <th>Battle Pass</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <strong>Clear Drops</strong>
                    </td>
                    <td>
                      <p>Dailies and weeklies: 90 per day, 100 per week</p>
                      <p>Limbo: Up to 600 every two weeks</p>
                    </td>
                    <td>2700 (+300 Crystal Drops)</td>
                    <td>
                      <p>Collector’s: 1260</p>
                      <p>Deluxe: 1660</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Total</strong>
                    </td>
                    <td>4300 (Month, 30 days/4 weeks)</td>
                    <td>3000</td>
                    <td>1260 or 1660</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Extra</strong>
                    </td>
                    <td colSpan={3}>
                      +10 Summons from the Pawnshop from Weeklies, +2 Summons
                      from free BP
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h5>Drop Rates</h5>
              <ul>
                <li>
                  Basic rate of summoning 6 star characters:{' '}
                  <strong>1.5%</strong>
                </li>
                <li>
                  Basic rate of summoning 5 star characters:{' '}
                  <strong>8.5%</strong>
                </li>
                <li>
                  Basic rate of summoning 4 star characters:{' '}
                  <strong>40%</strong>
                </li>
                <li>
                  Basic rate of summoning 3 star characters:{' '}
                  <strong>45%</strong>
                </li>
                <li>
                  Basic rate of summoning 2 star characters: <strong>5%</strong>
                  .
                </li>
              </ul>
              <p>
                Each summon costs 180 Clear Drops or one Unilog. Every 10
                summons guarantees at least 1 4-star or better character. Under
                this rule, the overall chance of drawing a 6✦ is 2,36%. If you
                have not obtained any 6✦ character after 60 summons, the rate of
                summoning a 6✦ character in the next summon will rise from 1.5%
                to 4%. After that, every summon that doesn’t grant a 6✦
                character will increase the rate by 2.5%.
              </p>
              <ul>
                <li>Every 70 summons guarantees 1 6✦ character.</li>
                <li>
                  After you have obtained a 6✦ character, the rate will be reset
                  to 1.5%.
                </li>
                <li>
                  On limited and rate up banners, there’s a 50% chance you will
                  get the featured character. If you lose the first 50/50, then
                  the next 6✦ is guaranteed to be the featured character.
                </li>
                <li>
                  Pity is shared between Limited and Rate Up banners, and it
                  carries over as well.
                </li>
              </ul>
              <p>
                To guarantee a specific 6✦, a player can either pull on that
                character’s rate up banner, or exchange 240 Album of the Lost
                (obtained from new 5✦ and 6✦ as well as from dupes) at the
                Pawnshop. The available character at the Pawnshop changes on a
                monthly basis, and 5✦ characters can be obtained through this
                method as well, for the price of 60 Album of the Lost.
                Furthermore, despite new 6✦ being considered “Limited”, they get
                added to the regular poll three patches after their original
                debut. It is recommended to pull only on rate up banners, and
                ignore the standard banner (“Amid the Water”).
              </p>
              <p>
                When it comes to Monetization, the only things that can only be
                obtained through paid means are certain skins and profile icons.
                Each Battle Pass features a skin and profile icon, and each
                patch cycle has two paid skins available for 880 Crystal Drops
                (paid currency) each. There are also free skins available
                through events and if you are wondering, yes, skins are cosmetic
                only, they don’t offer any stats.
              </p>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="Videos" />
              <p>
                The videos below showcase the main features of the game - menus,
                characters, exploration and combat. Give them a watch!
              </p>
              <Row className="video">
                <Col xs={12} lg={6}>
                  <YoutubeEmbed embedId="FN-K3YcbBV4" />
                </Col>
                <Col xs={12} lg={6}>
                  <YoutubeEmbed embedId="ZQeBJLjBRf4" />
                </Col>
                <Col xs={12} lg={6}>
                  <YoutubeEmbed embedId="JKO-LpnCNfY" />
                </Col>
                <Col xs={12} lg={6}>
                  <YoutubeEmbed embedId="LuIHm5qGsus" />
                </Col>
              </Row>
              <SectionHeader title="Official images" />
              <Row xs={1} xl={2}>
                <Col>
                  <StaticImage
                    src="../../../images/reverse/promo1.jpg"
                    alt="Guides"
                  />
                </Col>
                <Col>
                  <StaticImage
                    src="../../../images/reverse/promo2.jpg"
                    alt="Guides"
                  />
                </Col>
                <Col>
                  <StaticImage
                    src="../../../images/reverse/promo3.jpg"
                    alt="Guides"
                  />
                </Col>
                <Col>
                  <StaticImage
                    src="../../../images/reverse/promo4.jpg"
                    alt="Guides"
                  />
                </Col>
                <Col>
                  <StaticImage
                    src="../../../images/reverse/promo5.jpg"
                    alt="Guides"
                  />
                </Col>
                <Col>
                  <StaticImage
                    src="../../../images/reverse/promo6.jpg"
                    alt="Guides"
                  />
                </Col>
              </Row>
              <SectionHeader title="Trailers" />
              <Row className="video">
                <Col xs={12} lg={6}>
                  <YoutubeEmbed embedId="NAZyj0b_JKY" />
                </Col>
                <Col xs={12} lg={6}>
                  <YoutubeEmbed embedId="WCCueDRq3Gs" />
                </Col>
                <Col xs={12} lg={6}>
                  <YoutubeEmbed embedId="Dqf_PUS3wGg" />
                </Col>
              </Row>
              <SectionHeader title="Official links" />
              <ul>
                <li>
                  Official Website:{' '}
                  <a
                    href="https://re1999.bluepoch.com/en"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      width="18"
                      icon={faArrowUpRightFromSquare}
                    />{' '}
                    Open in new window
                  </a>
                </li>
                <li>
                  Official Discord:{' '}
                  <a
                    href=" https://discord.com/invite/hMw6gcvCfp"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      width="18"
                      icon={faArrowUpRightFromSquare}
                    />{' '}
                    Open in new window
                  </a>
                </li>
                <li>
                  Official YouTube:{' '}
                  <a
                    href="https://www.youtube.com/@Reverse1999"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      width="18"
                      icon={faArrowUpRightFromSquare}
                    />{' '}
                    Open in new window
                  </a>
                </li>
                <li>
                  Official Twitter:{' '}
                  <a
                    href="https://twitter.com/Reverse1999_GL"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      width="18"
                      icon={faArrowUpRightFromSquare}
                    />{' '}
                    Open in new window
                  </a>
                </li>
                <li>
                  Official Facebook:{' '}
                  <a
                    href="https://www.facebook.com/reverse1999global"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      width="18"
                      icon={faArrowUpRightFromSquare}
                    />{' '}
                    Open in new window
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Setting</a>
                </li>
                <li>
                  <a href="#section-2">Gameplay</a>
                </li>
                <li>
                  <a href="#section-3">Game Modes</a>
                </li>
                <li>
                  <a href="#section-4">Income and Monetization</a>
                </li>
                <li>
                  <a href="#section-5">Official links</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default RevGuidesIntroPage;

export const Head: React.FC = () => (
  <Seo
    title="Introduction to the game | Reverse: 1999 | Prydwen Institute"
    description="Everything you need to know about Reverse: 1999 gacha game."
    game="reverse"
  />
);
